
export default {
  data () {
    return {
      scrollElements: []
    }
  },
  mounted () {
    window.addEventListener('scroll', this.applyAnimation)
  },
  destroyed () {
    window.removeEventListener('scroll', this.applyAnimation)
  },
  methods: {
    applyDirection (getContent) {
      getContent.forEach((element, index) => {
        element.direction = index % 2 === 0 ? 'left' : 'right'
      })
      return getContent
    },
    applyAnimation () {
      var reveals = document.querySelectorAll('.reveal-card')
      for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight
        var elementTop = reveals[i].getBoundingClientRect().top
        var elementVisible = 150
        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add('active-card')
        } else {
          reveals[i].classList.remove('active-card')
        }
      }
    },
    scrollToTop () {
      // Scroll to the top of the page
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Optional: Adds smooth scrolling behavior
      })
    }
    // trackSite () {
    //   this.$gtag.screenview({
    //     app_name: 'rentass_site',
    //     screen_name: this.$route.name
    //   })
    // }
  }
}
